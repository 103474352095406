import { Button, Cascader, DatePicker, Divider, Form, Input, InputNumber, message, Radio, Select, Switch } from 'antd'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// COMPONENTS
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import { formItemLayout, tailFormItemLayout } from '../../Controllers/form'
import { addTask, editTask } from '../../Store/Actions/tasks'
import { addActivity } from '../../Store/Actions/activity'
import { createTask, getTaskById, updateTask } from '../../Services/task'
import SelectDebounce from '../SelectDebounce'
import { createActivity } from '../../Services/activity'
import { objectDifference } from '../../Controllers/objectDifference'
import { fetchAList, fetchDeepInformation, fetchUserList } from '../../Controllers/fetchLists'
import { reminder } from '../../Content/enquiries'
import { SAVE_AND_ADD, SAVE_AND_CLOSE } from '../../Controllers/buttonNames'
import { updateRevision } from '../../Services/Sales/enquiries'
import { taskOptions } from '../../Content/tasks'
import { getProjectById } from '../../Services/projects'
import { refreshComponent } from '../../Store/Actions/userSession'
import dateFormat from '../../Content/dateFormat'
import ReporteeSelect from '../ReporteeSelect'

const TaskForm = ({ association_id, handleClose, edit, data, handleActions, fromSE, addAnother, ...props }) => {
	const [form] = Form.useForm()
	const { Option } = Select
	const { token, id } = useSelector(state => state.userSession)

	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const [reminderSwitch, setReminderSwitch] = useState(data?.set_reminder || false)
	const [repeatSwitch, setRepeatSwitch] = useState(data?.repeat_custom || false)
	const [recurringSwitch, setRecurringSwitch] = useState(data?.recurring_tasks || false)
	const [actionIDs, setActionIDs] = useState(props?.actionIDs || [])
	const [cascader, setCascader] = useState(data?.association === 'Projects')
	const [cascaderOptions, setCascaderOptions] = useState([])

	const location = useLocation()
	const related_to = _.startCase(_.toLower(location.pathname.split('/')[1]))
	const related_association = _.startCase(_.toLower(location.pathname.split('/')[2]))
	const [relatedTo, setRelatedTo] = useState(related_to)

	const defaultAssociation = () => {
		if (relatedTo === 'Sales') {
			return 'Accounts'
		}
		if (relatedTo === 'Contracts') {
			return 'Contracts'
		}
		if (relatedTo === 'Projects') {
			return 'Projects'
		}
		return null
	}

	const [association, setAssociation] = useState(data?.association || defaultAssociation(relatedTo))
	const [resetOptions, setResetOptions] = useState(false)

	const formData = data
		? {
				...data,
				assignee: {
					key: data.assignee,
					label: data.assignee_name,
					value: data.assignee
				},
				association_id: {
					key: data.association_id,
					label: data.association_name,
					value: data.association_id
				},
				end_date: moment.unix(data.end_date),
				reminder: moment.unix(data.reminder),
				repeat_start_date: moment.unix(data?.repeat_start_date),
				repeat_end_date: moment.unix(data?.repeat_end_date),
				start_date: moment.unix(data.start_date),
				associated_activity: data.deliverable_id && [data.deliverable_id, data.activity_id || ''],
				collaborators:
					data?.collaborators_data?.length &&
					data?.collaborators_data?.map(a => ({
						key: a.id,
						label: a.name,
						value: a.id
					}))
		  }
		: {
				start_date: moment(),
				priority: 'Medium',
				status: 'Not Started'
		  }

	const onAdd = async (values, e) => {
		setLoading(true)
		const dataObject = {
			..._.omit(values, ['associated_activity']),
			task_type: fromSE ? 'nobid' : 'normal',
			association_id: values?.association_id?.value || association_id || null,
			association: association_id ? related_association : association,
			assignee: values?.assignee?.value,
			assignee_name: values?.assignee?.label,
			assignor: id,
			name: values.name,
			description: values.description,
			end_date: moment(values.end_date).unix(),
			priority: values.priority,
			reminder: moment(values.reminder).unix(),
			repeat_start_date: values.recurring_tasks ? moment(values.repeat_start_date).unix() : undefined,
			repeat_end_date: values.recurring_tasks ? moment(values.repeat_end_date).unix() : undefined,
			start_date: moment(values.start_date).unix(),
			status: values.status,
			related_to: values.related_to || '',
			deliverable_id: values?.associated_activity?.length && values?.associated_activity[0],
			activity_id: values?.associated_activity?.length && values?.associated_activity[1],
			collaborators: values?.collaborators?.length && values?.collaborators?.map(a => a.value)
		}

		const activityObject = {
			association_id: association_id || values?.association_id?.value,
			activity_type: 'Task',
			subject: values.name,
			description: `"${values.name}" Completed.`
		}
		try {
			const { data } = await createTask(token, dataObject)
			const res = await getTaskById(token, data.data.id)
			setLoading(false)
			message.success('Task added successfully!')
			dispatch(addTask(res.data.data))
			dispatch(refreshComponent())
			if (fromSE) {
				await updateRevision(token, {
					id: props?.revisionID,
					scope_and_evaluation: { no_bid_actions: [data.data.id, ...actionIDs] }
				})
			}
			if (handleActions) {
				handleActions({ id: data.data.id, ...dataObject })
			}

			setActionIDs(prev => [data.data.id, ...prev])
			if (values.status === 'Completed' && activityObject.association_id) {
				const { data } = await createActivity(token, activityObject)
				dispatch(addActivity({ id: data.data.id, ...activityObject }))
			}
			form.resetFields()
			dispatch(refreshComponent())
			if (e && handleClose) {
				handleClose()
			}
		} catch (error) {
			setLoading(false)
			form.resetFields()
			message.error(error.response?.data?.message || 'Something went wrong')
		}
	}

	const onEdit = async (values, e) => {
		setLoading(true)
		const dataObject = {
			..._.omit(values, ['associated_activity']),
			task_type: data.task_type,
			association: values?.association || data.association || association,
			association_id: values?.association_id?.value || data.association_id,
			assignee: values?.assignee?.value || data.assignee,
			assignee_name: values?.assignee?.label || data.assignee_name,
			assignor: data.assignor,
			end_date: moment(values.end_date).unix(),
			reminder: moment(values.reminder).unix(),
			repeat_start_date: values.recurring_tasks ? moment(values.repeat_start_date).unix() : undefined,
			repeat_end_date: values.recurring_tasks ? moment(values.repeat_end_date).unix() : undefined,
			start_date: moment(values.start_date).unix(),
			deliverable_id: values?.associated_activity?.length && values?.associated_activity[0],
			activity_id: values?.associated_activity?.length && values?.associated_activity[1],
			collaborators: values?.collaborators?.length && values?.collaborators?.map(a => a.value)
		}
		const difference = objectDifference(data, dataObject)
		const activityObject = {
			association_id: data.association_id || '',
			activity_type: 'Action',
			subject: values.name,
			description: `"${values.name}" Completed.`
		}

		try {
			await updateTask(token, { id: data.id, ...difference })
			const res = await getTaskById(token, data.id)
			if (handleActions) {
				handleActions({ id: data.id, ...dataObject })
			}
			dispatch(
				editTask({
					id: data.id,
					data: res.data.data
				})
			)
			if (values.status === 'Completed') {
				const { data } = await createActivity(token, activityObject)
				dispatch(
					addActivity({
						id: data.data.id,
						...activityObject
					})
				)
			}
			if (e && handleClose) {
				handleClose()
			}
			setLoading(false)
			message.success('Action updated successfully!')
		} catch (error) {
			setLoading(false)
			message.error(error.response?.data?.message || 'Something went wrong.')
		}
	}

	const handleSubmit = async (e, edit) => {
		try {
			const q = form.getFieldsError(['association_id'])
			if (!q[0]?.errors?.length) {
				const values = await form.validateFields()
				edit ? await onEdit(values, e) : await onAdd(values, e)
			}
		} catch (error) {
			console.log('er', error)
		}
	}
	const handleCascaderOptions = w => {
		const { ps } = w
		const options = ps.map((one, i) => ({
			label: one.display_name,
			key: i,
			value: one.ps_group_id,
			children: one?.ps_data?.billable_tasks?.length
				? one.ps_data.billable_tasks.map((task, j) => ({
						label: task.name,
						value: task.id,
						key: j
				  }))
				: null
		}))

		setCascaderOptions(options)
	}
	const handleAssociation = async (e, association) => {
		const associationId = e.value
		try {
			const {
				data: { data }
			} = await fetchDeepInformation(associationId, association, token)
			if (data?.access_specifier === 'Private') {
				form.setFields([
					{
						name: 'association_id',
						errors: ['You are not authorized to use this entity to create the task.']
					}
				])
			} else {
				form.setFields([
					{
						name: 'association_id',
						value: e,
						errors: null
					}
				])
				if (association === 'Projects') {
					setCascader(true)
					form.setFieldsValue({
						task: []
					})
					handleCascaderOptions(data)
				}
			}
		} catch (e) {}
	}

	useEffect(async () => {
		if (data && data.association === 'Projects') {
			try {
				const { data: projectData } = await getProjectById(token, data.association_id)
				handleCascaderOptions(projectData.data)
			} catch (e) {
				console.log(e)
			}
		}
	}, [])

	return (
		<div className='mt-5'>
			<Form name='control-hooks' form={form} {...formItemLayout} initialValues={formData} scrollToFirstError>
				<Form.Item
					label='Action Name'
					name='name'
					rules={[
						{
							required: true,
							message: 'Please input action name!'
						}
					]}
				>
					<Input placeholder='Action / Task' />
				</Form.Item>
				<Form.Item
					label='Assignee'
					name='assignee'
					rules={[{ required: true, message: 'Please assign this task to someone!' }]}
				>
					<ReporteeSelect form={form} fieldName='assignee' currentValue={formData ? formData?.assignee?.label : null} />
				</Form.Item>
				<Form.Item label='Type' name='type'>
					<Select placeholder='Select Task Type'>
						{taskOptions.task_type.map((option, i) => (
							<Option value={option} key={i}>
								<p className='capitalize'>{option}</p>
							</Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item label='Description' name='description'>
					<Input.TextArea rows={4} />
				</Form.Item>
				<Form.Item
					label='Date'
					style={{
						marginBottom: 0
					}}
				>
					<Form.Item
						name='start_date'
						style={{
							display: 'inline-block',
							width: 'calc(50%)'
						}}
						rules={[
							{
								required: true,
								message: 'Please input start date!'
							}
						]}
					>
						<DatePicker placeholder='Start Date' format={dateFormat} />
					</Form.Item>
					<Form.Item
						name='end_date'
						style={{
							display: 'inline-block',
							width: 'calc(50%)'
						}}
					>
						<DatePicker placeholder='End Date' format={dateFormat} />
					</Form.Item>
				</Form.Item>
				<Form.Item label='Estimated Hours' name='estimated_hours'>
					<InputNumber placeholder='in Hours' />
				</Form.Item>
				<div className='py-2 italic text-bell-gray' hidden={association_id}>
					Relationship
				</div>
				<Form.Item
					label='Related To'
					name='related_to'
					initialValue={related_to !== 'Tasks' && relatedTo}
					hidden={association_id}
				>
					<Select
						placeholder='Related to..'
						disabled={related_to !== 'Tasks'}
						defaultChecked={related_to !== 'Tasks' && relatedTo}
						onChange={e => setRelatedTo(e)}
					>
						{['Sales', 'Contracts', 'Projects'].map((option, i) => (
							<Option value={option} key={i}>
								<p className='capitalize'>{option}</p>
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					label='Association'
					style={{
						marginBottom: 0
					}}
					hidden={association_id}
				>
					<Form.Item
						name='association'
						style={{
							display: 'inline-block',
							width: 'calc(30%)'
						}}
					>
						<Select
							value={association}
							onChange={e => {
								setAssociation(e)
								form.setFieldsValue({
									association_id: null
								})
								setResetOptions(true)
							}}
							defaultValue={association}
						>
							{taskOptions.association[relatedTo]?.map(option => (
								<Option value={option}>{option}</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						name='association_id'
						style={{
							display: 'inline-block',
							width: 'calc(70%)'
						}}
					>
						<SelectDebounce
							showSearch
							placeholder={association && `Search ${association}`}
							fetchOptions={e => {
								setResetOptions(false)
								const w = fetchAList(e, association, token)
								return w
							}}
							resetOptions={resetOptions}
							onChange={e => handleAssociation(e, association)}
						/>
					</Form.Item>
				</Form.Item>
				<Form.Item label='Associated Activity' hidden={!cascader} name='associated_activity'>
					<Cascader options={cascaderOptions} placeholder='Select Deliverable and Activity' />
				</Form.Item>
				<div className='py-2 italic text-bell-gray'>Additional Information</div>
				<Form.Item
					label='Priority'
					name='priority'
					rules={[
						{
							required: true,
							message: 'Please input priority!'
						}
					]}
				>
					<Radio.Group>
						{taskOptions.priority.map((option, i) => (
							<Radio.Button value={option} key={i}>
								<p className='capitalize'>{option}</p>
							</Radio.Button>
						))}
					</Radio.Group>
				</Form.Item>
				<Form.Item
					label='Status'
					name='status'
					rules={[
						{
							required: true,
							message: 'Please input status!'
						}
					]}
				>
					<Radio.Group>
						{taskOptions.status.map((option, i) => (
							<Radio.Button value={option} key={i}>
								<p className='capitalize'>{option}</p>
							</Radio.Button>
						))}
					</Radio.Group>
				</Form.Item>
				<div className='py-2 italic text-bell-gray'>Reminder Information</div>
				<Form.Item label='Set Reminder' name='set_reminder'>
					<Switch onChange={e => setReminderSwitch(e)} defaultChecked={reminderSwitch} />
				</Form.Item>
				{reminderSwitch && (
					<div>
						<Form.Item label='Date & Time' name='reminder'>
							<DatePicker showTime format='DD/MM/YYYY HH:mm' placeholder='Date and Time' />
						</Form.Item>

						<Form.Item label='Repeat Task?' name='recurring_tasks'>
							<Switch
								onChange={e => {
									setRecurringSwitch(e)
									if (!e) {
										form.resetFields(['frequency', 'repeat', 'repeat_custom', 'repeat_start_date', 'repeat_end_date'])
									}
								}}
								defaultChecked={recurringSwitch}
							/>
						</Form.Item>
						{recurringSwitch && (
							<div>
								<Form.Item label='Frequency' name='frequency'>
									<Select placeholder='Frequency'>
										{reminder.frequency.map((option, i) => (
											<Option value={option} key={i}>
												<p className='capitalize'>{option}</p>
											</Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item label='Repeats' name='repeat'>
									<Select
										placeholder='Repeats'
										onChange={e => {
											setRepeatSwitch(e === 'Custom')
										}}
									>
										{reminder.repeats.map((option, i) => (
											<Option value={option} key={i}>
												<p className='capitalize'>{option}</p>
											</Option>
										))}
									</Select>
								</Form.Item>

								{repeatSwitch && (
									<Form.Item label='Every' name='repeat_custom'>
										<Select placeholder='Repeat every 10 days?'>
											{reminder.custom_repeat().map((option, i) => (
												<Option value={option} key={i}>
													<p className='capitalize'>{option}</p>
												</Option>
											))}
										</Select>
									</Form.Item>
								)}

								<Form.Item
									label='Date Range'
									style={{
										marginBottom: 0
									}}
								>
									<Form.Item
										name='repeat_start_date'
										style={{
											display: 'inline-block',
											width: 'calc(50%)'
										}}
										rules={[
											{
												required: true,
												message: 'Please input start date!'
											}
										]}
									>
										<DatePicker placeholder='Start Date' format={dateFormat} />
									</Form.Item>
									<Form.Item
										name='repeat_end_date'
										style={{
											display: 'inline-block',
											width: 'calc(50%)'
										}}
									>
										<DatePicker placeholder='End Date' format={dateFormat} />
									</Form.Item>
								</Form.Item>
							</div>
						)}
					</div>
				)}
				<Form.Item label='Collaborators' name='collaborators'>
					<SelectDebounce
						isMultiple
						showSearch
						placeholder='Add Collaborators'
						fetchOptions={e => fetchUserList(e, token)}
						resetOptions={resetOptions}
					/>
				</Form.Item>
				<div className='py-2 italic text-bell-gray'>Comments</div>
				<Form.Item label='Comments' name='comments'>
					<Input.TextArea rows={4} />
				</Form.Item>
				<Form.Item {...tailFormItemLayout}>
					{!edit && addAnother && (
						<>
							<Button type='secondary' htmlType='submit' loading={loading} onClick={() => handleSubmit('', edit)}>
								{SAVE_AND_ADD}
							</Button>
							<Divider type='vertical' />
						</>
					)}
					<Button type='primary' htmlType='submit' loading={loading} onClick={() => handleSubmit('close', edit)}>
						{handleClose ? SAVE_AND_CLOSE : 'Save'}
					</Button>
				</Form.Item>
			</Form>
		</div>
	)
}

export default TaskForm
