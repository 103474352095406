/* eslint-disable no-nested-ternary */
import React, { useContext, useState, useRef } from 'react'
import { Table, Input, Button, Form, Divider, InputNumber, Space, Popconfirm } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { v4 } from 'uuid'
import { useSelector } from 'react-redux'
import accounting from '../../../Controllers/accountingNumbers'
import SelectDebounce from '../../SelectDebounce'
import { fetchPSList } from '../../../Controllers/fetchLists'
import DrawerComponent from '../../DrawerComponent'
import BillableTasksForm from '../BillableTasksForm'

const EditableContext = React.createContext(null)

const EditableRow = ({ index, ...props }) => {
	const [form] = Form.useForm()
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	)
}

const initialItem = {
	key: v4(),
	display_name: '',
	ps_group_id: '',
	quantity: '',
	rate: '',
	order_value: '',
	updated: false
}

const EditableCell = ({
	title,
	value,
	editable,
	children,
	dataIndex,
	record,
	handleSave,
	handleRemove,
	...restProps
}) => {
	const { token } = useSelector(state => state.userSession)
	const inputRef = useRef(null)
	const form = useContext(EditableContext)

	const save = async w => {
		// eslint-disable-next-line react/destructuring-assignment
		const values = await form.validateFields()
		const updatedObject = w?.data?.type ? { ...values, type: w?.data?.type || 'Service', ps_data: w.data } : values
		if (editable) {
			handleSave({ ...record, ...updatedObject })
		}
	}
	const renderChildren = {
		display_name: <Input ref={inputRef} onPressEnter={save} onChange={save} onBlur={save} />,
		ps_group_id: (
			<SelectDebounce
				showSearch
				placeholder='Search Products & Services'
				fetchOptions={e => fetchPSList(e, token)}
				onChange={(e, w) => save(w)}
			/>
		),
		quantity: (
			<InputNumber
				// quantity should be at least one more than the quantity already invoiced, else there is no need to add variation to this product.
				min={record?.type === 'Goods' && record?.history && record.history.quantity_invoiced + 1}
				ref={inputRef}
				onPressEnter={save}
				onChange={save}
				onBlur={save}
				// disable if all of quantity is invoiced already.
				disabled={
					record?.type === 'Service' ||
					(record?.history?.quantity_invoiced || 0) === (record?.history?.total_quantity || 1)
				}
			/>
		),
		rate: (
			<Input
				ref={inputRef}
				onPressEnter={save}
				onChange={save}
				onBlur={save}
				disabled={
					record?.type === 'Goods' &&
					(record?.history?.quantity_invoiced || 0) === (record?.history?.total_quantity || 1)
				}
			/>
		)
	}
	return (
		<td {...restProps}>
			{editable ? (
				<Form.Item
					style={{
						margin: 0
					}}
					name={dataIndex}
					initialValue={record.updated ? record[dataIndex] : ''}
				>
					{renderChildren[dataIndex]}
				</Form.Item>
			) : (
				<div>{children}</div>
			)}
		</td>
	)
}

const ProductServicesTable = ({ data, handleData, handleClose, hideFinancialInformation }) => {
	const [dataSource, setDataSource] = useState(data || [initialItem])
	const [billableTasksDrawer, setBillableTasksDrawer] = useState(false)

	const handleSave = row => {
		const updatedRow = {
			...row,
			quantity: Number(row.quantity || 1),
			rate: Number(row.rate),
			order_value: Number(row.rate) * Number(row.quantity || 1)
		}
		setDataSource(prev =>
			prev.map(x => {
				if (x.key === row.key) {
					return { ...x, ...updatedRow, updated: true }
				}
				return x
			})
		)
	}

	const handleAdd = async () => {
		setDataSource(prev => [
			...prev,
			{
				key: v4(),
				display_name: '',
				ps_group_id: '',
				quantity: '',
				rate: '',
				order_value: '',
				updated: false
			}
		])
	}

	const handleRemove = async row => {
		if (dataSource?.length > 1) {
			setDataSource(prev => prev.filter(x => x.key !== row.key))
		} else {
			setDataSource([initialItem])
		}
	}
	const columns = hideFinancialInformation
		? [
				{
					title: 'Display Name',
					dataIndex: 'display_name',
					editable: true
				},
				{
					title: 'Product / Service',
					dataIndex: 'ps_group_id',
					editable: true
				},
				{
					title: 'Quantity',
					dataIndex: 'quantity',
					width: '10%',
					editable: true
				},
				{
					title: 'Action',
					key: 'action',
					render: (_, w) => (
						<Space>
							<Popconfirm
								title={
									<div className='w-96'>
										Choosing to duplicate &apos;<strong>With Tasks</strong>&apos; will duplicate each activity will all
										their tasks and any new tasks added to any activity will automatically be associated to its
										duplicates as well.
									</div>
								}
								icon={false}
								showArrow
								okText='With Tasks'
								cancelText='Without Tasks'
								placement='bottom'
								onConfirm={async () => {
									const duplicateEntry = {
										...w,
										display_name: `Copy of ${w.display_name}`,
										key: v4(),
										ps_group_id: ''
									}
									const arr = [
										...dataSource.slice(0, dataSource.indexOf(w) + 1),
										duplicateEntry,
										...dataSource.slice(dataSource.indexOf(w) + 1)
									]
									setDataSource(arr)
								}}
								onCancel={async () => {
									const duplicateEntry = {
										...w,
										display_name: `Copy of ${w.display_name}`,
										key: v4(),
										ps_group_id: '',
										ps_data: w.ps_data
											? {
													...w.ps_data,
													billable_tasks: w.ps_data.billable_tasks
														? w.ps_data.billable_tasks.map(a => ({ ...a, key: v4(), id: v4() }))
														: ''
											  }
											: ''
									}
									const arr = [
										...dataSource.slice(0, dataSource.indexOf(w) + 1),
										duplicateEntry,
										...dataSource.slice(dataSource.indexOf(w) + 1)
									]
									setDataSource(arr)
								}}
							>
								<Button type='link'>Duplicate</Button>
							</Popconfirm>

							<DrawerComponent
								form={
									<BillableTasksForm
										handleClose={() => setBillableTasksDrawer(false)}
										data={
											w?.ps_data?.billable_tasks?.length &&
											w?.ps_data?.billable_tasks?.map(q => ({ ...q, key: v4(), updated: true }))
										}
										handleData={q =>
											setDataSource(prev =>
												prev.map(x => {
													if (x.ps_group_id?.value === w.ps_group_id?.value) {
														return { ...w, ps_data: { ...w.ps_data, billable_tasks: q } }
													}
													return x
												})
											)
										}
									/>
								}
								visible={billableTasksDrawer[w.ps_group_id.value]}
								onOpen={() => setBillableTasksDrawer({ [w.ps_group_id.value]: true })}
								onClose={() => setBillableTasksDrawer(false)}
								buttonTitle='Update Activities'
								drawerWidth={1000}
								buttonType='link'
							/>
							<Button onClick={() => handleRemove(w)} icon={<DeleteOutlined />} danger type='link' />
						</Space>
					)
				}
		  ]
		: [
				{
					title: 'Display Name',
					dataIndex: 'display_name',
					editable: true
				},
				{
					title: 'Product / Service',
					dataIndex: 'ps_group_id',
					editable: true
				},
				{
					title: 'Quantity',
					dataIndex: 'quantity',
					width: '10%',
					editable: true
				},
				{
					title: 'Rate',
					dataIndex: 'rate',
					width: '10%',
					editable: true,
					hidden: true
				},
				{
					title: 'Order Value',
					key: 'order_value',
					render: (_, record) => <div> {accounting(record.order_value)}</div>
				},
				{
					title: 'Action',
					key: 'action',
					render: (_, w) => (
						<Button onClick={() => handleRemove(w)} type='link' disabled={w?.history?.amount_invoiced}>
							Remove
						</Button>
					)
				}
		  ]

	const mergerColumns = columns.map(col => {
		if (!col.editable) {
			return col
		}

		return {
			...col,
			onCell: record => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave,
				handleRemove
			})
		}
	})

	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell
		}
	}
	return (
		<div className='space-y-4'>
			<Table
				components={components}
				rowClassName={() => 'editable-row'}
				bordered
				dataSource={dataSource}
				columns={mergerColumns}
				pagination={false}
			/>
			<Button type='dashed' onClick={handleAdd} block icon={<PlusOutlined />}>
				Add Item
			</Button>
			<Divider />
			<div className='grid place-items-center pt-10'>
				<Button
					onClick={() => {
						handleData(dataSource?.filter(x => x.updated))
						handleClose()
					}}
					type='primary'
				>
					Save & Close
				</Button>
			</div>
		</div>
	)
}

export default ProductServicesTable
