export const getToken = () => sessionStorage.getItem('token') || null

export const getRole = () => sessionStorage.getItem('role') || null

// export const getUserPassword = () => {
//   const e = sessionStorage.getItem("pwd");
//   var decodedString = window.atob(e);
//   return decodedString;
// };

// remove the email from the session storage
export const removeUserSession = () => {
	sessionStorage.removeItem('token')
	sessionStorage.removeItem('role')
}

// set the email from the session storage
export const setUserSession = (name, value) => {
	sessionStorage.setItem(name, value)
}

// export const setUserPassword = (password) => {
//   var encodedString = window.btoa(password);
//   sessionStorage.setItem("pwd", encodedString);
// };
